import Image from "next/image";

export function Logo() {
	return (
		<div className="relative inline-flex w-40 h-auto aspect-4/1 items-center text-gray-900 xl:w-48">
			<Image
				src="/logo-ipbtraining-landscape.webp"
				fill
				alt="Logo IPB Training"
				className="min-w-full object-contain"
				priority
			/>
		</div>
	);
}
