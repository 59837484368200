import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HistoryIcon from "@mui/icons-material/History";
import Logout from "@mui/icons-material/Logout";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
	Avatar,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import Swal from "sweetalert2";

import { useAuth } from "@/context/auth";

export default function AccountMenu() {
	const { auth, handleLogout } = useAuth();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const router = useRouter();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutConfirmation = () => {
		Swal.fire({
			title: "Ingin keluar?",
			showCancelButton: true,
			confirmButtonText: "Keluar",
			cancelButtonText: "Batal",
			denyButtonText: "Don't save",
			confirmButtonColor: "#E74040",
		}).then((result) => {
			if (result.isConfirmed) {
				handleLogout();
				router.push("/login");
			}
		});
	};

	return (
		<>
			<Tooltip title="Menu">
				<IconButton
					onClick={handleClick}
					size="small"
					sx={{ ml: 2 }}
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					{auth.photo ? (
						<Image
							src={auth.photo}
							alt={`Avatar ${auth.name}`}
							width="40"
							height="40"
							className="rounded-full border shadow-md"
						/>
					) : (
						<Avatar className="size-24 xl:size-[40px]">
							<p className="text-[1.2vw]">{auth.name?.charAt(0)}</p>
						</Avatar>
					)}
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				disableScrollLock={true}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&::before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				className="text-base"
			>
				<Link href="/profile">
					<MenuItem onClick={handleClose} className="text-base">
						<ListItemIcon>
							<PermIdentityIcon />
						</ListItemIcon>
						Profile
					</MenuItem>
				</Link>
				<Link href="/history">
					<MenuItem onClick={handleClose} className="text-base">
						<ListItemIcon>
							<HistoryIcon />
						</ListItemIcon>
						Training saya
					</MenuItem>
				</Link>
				<Link href="/wishlist">
					<MenuItem onClick={handleClose} className="text-base">
						<ListItemIcon>
							<FavoriteBorderOutlinedIcon />
						</ListItemIcon>
						Wishlist
					</MenuItem>
				</Link>
				<Link href="/participant">
					<MenuItem onClick={handleClose} className="text-base">
						<ListItemIcon>
							<GroupOutlinedIcon />
						</ListItemIcon>
						Data peserta
					</MenuItem>
				</Link>
				<Link href="/institution">
					<MenuItem onClick={handleClose} className="text-base">
						<ListItemIcon>
							<CorporateFareRoundedIcon />
						</ListItemIcon>
						Data instansi
					</MenuItem>
				</Link>
				<Divider />
				<MenuItem onClick={handleLogoutConfirmation} className="text-base">
					<ListItemIcon>
						<Logout />
					</ListItemIcon>
					Keluar
				</MenuItem>
			</Menu>
		</>
	);
}
