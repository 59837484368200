import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

const defaultDescription =
	"Learning, Sharing, Networking - Excellence hub for professional and vocational skill based on applied life science.";
const defaultOGURL = "https://ipbtraining.com/";
const defaultKeywords = "training, haccp, ipb, kepakaran, pest, iso";

export type MetaProps = {
	title?: string;
	description?: string;
	url?: string;
	ogImage?: string;
	keywords?: string;
	canonical?: string;
};

export default function Meta(props: MetaProps) {
	const { basePath } = useRouter();
	return (
		<>
			<Head>
				<meta charSet="UTF-8" key="charset" />
				<meta name="keywords" content={props.keywords || defaultKeywords} />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1"
					key="viewport"
				/>
				<link
					rel="apple-touch-icon"
					href={`${basePath}/apple-touch-icon.png`}
					key="apple"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href={`${basePath}/favicon-32x32.png`}
					key="icon32"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href={`${basePath}/favicon-16x16.png`}
					key="icon16"
				/>
				<link rel="icon" href={`${basePath}/favicon.ico`} key="favicon" />
				<meta property="og:url" content={props.url || defaultOGURL} />
				<meta property="og:title" content={props.title || "ipbtraining.com"} />
				<meta
					property="og:description"
					content={props.description || defaultDescription}
				/>
				<meta property="og:type" content="website" />
				<meta name="twitter:title" content={props.title || "ipbtraining.com"} />
				<meta
					name="twitter:description"
					content={props.description || defaultDescription}
				/>
				<meta name="twitter:site" content={props.url || defaultOGURL} />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:image" content={props.ogImage} />
				<meta property="og:image" content={props.ogImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta httpEquiv="imagetoolbar" content="no" />
			</Head>
			<NextSeo
				title={props.title ? `IPB Training - ${props.title}` : "IPB Training"}
				description={props.description || defaultDescription}
				canonical={props.canonical}
				openGraph={{
					title: props.title,
					description: props.description,
					url: props.canonical,
					locale: "id",
					site_name: "IPB Training",
				}}
			/>
		</>
	);
}
