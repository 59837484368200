import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HistoryIcon from "@mui/icons-material/History";
import Logout from "@mui/icons-material/Logout";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { IconButton, ListItemIcon, MenuItem } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import Swal from "sweetalert2";

import { useAuth } from "@/context/auth";
import { Logo } from "@/layout/Logo";

import { Button } from "../button/Button";
import AccountMenu from "../menu/AccountMenu";
import classNames from "classnames";
import { createPortal } from "react-dom";
import dynamic from "next/dynamic";

function NavbarImpl() {
	const router = useRouter();
	const [showNav, setShowNav] = useState(false);
	const { auth, isAuthenticated, handleLogout } = useAuth();

	function handleToggleNav() {
		if (typeof window === "undefined") return;
		if (showNav) {
			document.body.classList.remove("overflow-y-hidden");
			setShowNav(false);
		} else {
			window.scrollTo(0, 0);
			document.body.classList.add("overflow-y-hidden");
			setShowNav(true);
		}
	}

	const handleClickLogout = () => {
		handleToggleNav();
		Swal.fire({
			title: "Ingin keluar?",
			showCancelButton: true,
			confirmButtonText: "Keluar",
			cancelButtonText: "Batal",
			denyButtonText: "Don't save",
			confirmButtonColor: "#E74040",
		}).then((result) => {
			if (result.isConfirmed) {
				handleLogout();
				router.push("/login");
			}
		});
	};

	return (
		<nav className="no-print fixed top-0 z-50 flex w-full items-center bg-white px-4 py-4 lg:py-8 shadow-md">
			<div className="flex w-full items-center xl:basis-[20%]">
				<div className="text-left xl:hidden">
					<IconButton onClick={handleToggleNav}>
						<MenuRoundedIcon className="mx-auto text-4xl text-gray-500" />
					</IconButton>
				</div>
				<div className="mx-auto">
					<Link href="/">
						<Logo />
					</Link>
				</div>
			</div>
			<ul className="hidden basis-[50%] items-center justify-evenly text-gray-800 xl:visible xl:flex">
				<li className="text-center">
					<Link href="/technical">
						Pelatihan <br /> teknikal
					</Link>
				</li>
				<li className="text-center">
					<Link href="/ldp">
						Kepemimpinan <br />
						dan manajerial
					</Link>
				</li>
				<li className="text-center">
					<Link href="/ila">
						Program <br />
						bahasa
					</Link>
				</li>
			</ul>
			<div className="hidden basis-[30%] items-center justify-around xl:flex">
				<Link href="/schedule" className="text-center text-blue-ipbt">
					Jadwal
				</Link>
				<Link href="/blog" className="text-center text-blue-ipbt">
					Blog
				</Link>
				<Link
					href="https://online.ipbtraining.com"
					className="text-center text-blue-ipbt"
				>
					LMS
				</Link>
				<Link href="/faq" className="text-center text-blue-ipbt">
					FAQ
				</Link>
				{isAuthenticated ? (
					<AccountMenu />
				) : (
					<Link href="/login">
						<Button
							text="LOGIN"
							variant="blue"
							type="button"
							className="text-[0.8vw] font-bold"
							icon={<EastRoundedIcon className="ml-2" />}
							iconPosition="right"
						/>
					</Link>
				)}
			</div>
			{createPortal(
				<div
					className={classNames(
						"fixed inset-0 z-50 w-full overflow-y-auto bg-slate-50 transition-transform duration-300",
						{
							"translate-x-0": showNav,
							"-translate-x-full": !showNav,
						},
					)}
				>
					<ul className="mx-auto h-[calc(100vh-62px)] w-full max-w-screen-sm">
						<li className="p-4 border-b">
							<div className="flex items-center justify-between">
								<div className="text-center">
									<Logo />
								</div>
								<IconButton onClick={handleToggleNav}>
									<CloseRoundedIcon className="text-right text-4xl text-gray-500" />
								</IconButton>
							</div>
						</li>
						{auth.email && (
							<>
								<li>
									<Link href="/profile">
										<MenuItem>
											<ListItemIcon>
												<PermIdentityIcon />
											</ListItemIcon>
											Profile
										</MenuItem>
									</Link>
								</li>
								<li>
									<Link href="/history">
										<MenuItem>
											<ListItemIcon>
												<HistoryIcon />
											</ListItemIcon>
											Training saya
										</MenuItem>
									</Link>
								</li>
								<li>
									<Link href="/wishlist">
										<MenuItem>
											<ListItemIcon>
												<FavoriteBorderOutlinedIcon />
											</ListItemIcon>
											Wishlist
										</MenuItem>
									</Link>
								</li>
								<li>
									<Link href="/participant">
										<MenuItem>
											<ListItemIcon>
												<GroupOutlinedIcon />
											</ListItemIcon>
											Data peserta
										</MenuItem>
									</Link>
								</li>
								<li>
									<Link href="/institution">
										<MenuItem>
											<ListItemIcon>
												<CorporateFareRoundedIcon />
											</ListItemIcon>
											Data instansi
										</MenuItem>
									</Link>
								</li>
								<hr />
							</>
						)}
						<li>
							<Link
								href="/technical"
								className="block p-4"
								onClick={handleToggleNav}
							>
								Pelatihan teknikal
							</Link>
						</li>
						<li>
							<Link href="/ldp" className="block p-4" onClick={handleToggleNav}>
								Kepemimpinan dan manajerial
							</Link>
						</li>
						<li>
							<Link href="/ila" className="block p-4" onClick={handleToggleNav}>
								Program bahasa
							</Link>
							<hr />
						</li>
						<li>
							<Link
								href="/blog"
								className="block p-4 text-blue-ipbt"
								onClick={handleToggleNav}
							>
								Blog
							</Link>
						</li>
						<li>
							<Link
								href="https://online.ipbtraining.com"
								className="block p-4 text-blue-ipbt"
								onClick={handleToggleNav}
							>
								LMS
							</Link>
						</li>
						<li>
							<Link
								href="/faq"
								className="block p-4 text-blue-ipbt"
								onClick={handleToggleNav}
							>
								FAQ
							</Link>
							<hr />
						</li>
						{auth.email ? (
							<li>
								<MenuItem onClick={handleClickLogout}>
									<ListItemIcon>
										<Logout />
									</ListItemIcon>
									Logout
								</MenuItem>
							</li>
						) : (
							<li>
								<div className="px-4">
									<Link
										href="/login"
										className="mt-5 block w-full rounded-sm bg-blue-ipbt py-2 text-center text-white"
									>
										Login
									</Link>
								</div>
							</li>
						)}
					</ul>
				</div>,
				document.body,
			)}
		</nav>
	);
}

export const Navbar = dynamic(() => Promise.resolve(NavbarImpl), {
	ssr: false,
});
