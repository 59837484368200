import { CircularProgress } from "@mui/material";
import type { MouseEventHandler, PropsWithChildren } from "react";
import type React from "react";

const BUTTON_VARIANT_MAP = {
	blue: "bg-blue-ipbt text-white",
	green: "bg-lime-500 text-white",
	purple: "bg-purple-600 text-white",
	yellow: "bg-yellow-strong-ipbt",
	brown: "bg-brown-strong-ipbt text-white",
	gray: "bg-gray-300 text-gray-800",
	disabled: "bg-gray-300 cursor-not-allowed text-white",
	red: "bg-red-500 text-white",
} as const;

const BUTTON_SIZE_MAP = {
	small: "px-3 py-1.5 text-xl xl:px-[0.8vw] xl:py-[0.5vw] rounded-sm",
	medium: "py-3 px-3 xl:px-[1.2vw] xl:py-[0.7vw] rounded-sm",
	large:
		"rounded-[0.3rem] px-[1.2rem] py-[1rem] text-[0.8rem] font-medium xs:text-[1.2rem] xl:rounded-[0.2vw] xl:px-[1vw] xl:py-[0.5rem] xl:text-[1vw]",
} as const;

type ButtonProps = PropsWithChildren<{
	className?: string;
	// TODO(elianiva): migrate from text props to children props
	text?: string;
	size?: keyof typeof BUTTON_SIZE_MAP;
	loading?: boolean;
	variant: keyof typeof BUTTON_VARIANT_MAP;
	type: "submit" | "button";
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	fullWidth?: boolean;
	disabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}>;

export function Button(props: ButtonProps) {
	return (
		<button
			disabled={props.disabled}
			className={`
        ${props.fullWidth && "w-full"}
        ${BUTTON_VARIANT_MAP[props.disabled ? "disabled" : props.variant]}
        ${BUTTON_SIZE_MAP[props.size || "medium"]} 
        ${props.className}`}
			type={`${props.type}`}
			onClick={props.onClick}
		>
			<div className="flex items-center justify-center">
				{props.loading && <CircularProgress size={14} />}
				{!props.loading && (
					<>
						<div
							className={`flex items-center justify-center ${
								props.iconPosition === "left" ? "order-first" : "order-last"
							}`}
						>
							{props.icon}
						</div>
						{props.text ?? props.children}
					</>
				)}
			</div>
		</button>
	);
}
